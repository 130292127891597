import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography, Button } from 'antd'

import Layout from '../components/layout'
import Head from '../components/head'
import FreeTrialCta from '../components/free-trial-cta'

const { Title, Paragraph, Text } = Typography

const Alternatives = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      agicapLogoImage: file(relativePath: { eq: "logo-agicap.png" }) {
        childImageSharp {
          gatsbyImageData(height: 30)
        }
      }
      turbopilotLogoImage: file(relativePath: { eq: "logo-turbopilot.png" }) {
        childImageSharp {
          gatsbyImageData(height: 30)
        }
      }
      aurelienImage: file(relativePath: { eq: "aurelien-lovalente.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      benoitImage: file(relativePath: { eq: "benoit-maury.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      studiomaticImage: file(relativePath: { eq: "studiomatic-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      welybImage: file(relativePath: { eq: "welyb-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = 'Vous utilisez Agicap, TurboPilot, ou encore Fygr ? Découvrez RocketChart, le nouveau logiciel de trésorerie français pensé pour les TPE et PME.'

  return (
    <Layout>
      <Head
        title="Pourquoi RocketChart by Sellsy est la meilleure alternative à votre logiciel de trésorerie."
        description={description}
        canonical="https://rocketchart.co/alternatives/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 18 }} align='center'>
          <Title level={1}>RocketChart by Sellsy, la meilleure alternative des logiciels de trésorerie</Title>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 14 }} style={{ marginTop: '50px' }}>
          <Paragraph style={{ color: 'black' }}>Opter pour un bon logiciel de gestion de trésorerie représente plus que jamais un choix stratégique pour votre entreprise. Plusieurs compétiteurs existent sur le marché français : Agicap, TurboPilot, Fygr … Et il parait difficile de s’y retrouver.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Chez RocketChart notre mission est de vous proposer le meilleur logiciel de trésorerie : simple, intuitif, visuel, automatisé, nécessitant peu d’apprentissage. Très vite, et facilement, vous pourrez gérer, maîtriser et anticiper votre trésorerie.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Une 100aine d’entrepreneurs et de directeurs financiers - TPE, PME et Startups - nous font confiance. Grâce à RocketChart, ils gagnent un temps considérable et pilotent leur entreprise sereinement.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Alors pourquoi pas vous ? Économisez du temps avec suivi de trésorerie automatisé. Recentrez-vous sur votre activité. Prenez les bonnes décisions business à partir de projections fiables.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Si vous souhaitez remplacer votre logiciel de trésorerie actuel ou que vous voulez comparer les différentes solutions sur le marché avant de faire votre choix, nos comparatifs pourront vous aider. Vous comprendrez pourquoi RocketChart est sans doute la meilleure alternative.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Comparatifs</Title>
        </Col>

        <Col xs={24} lg={8} style={{ marginTop: 20 }}>
          <a href='/comparatif-rocketchart-vs-agicap/'>
            <div className="rc-quote-container" style={{ display: 'flex', alignItems: 'center' }}>
              <Paragraph>
                <GatsbyImage image={data.agicapLogoImage.childImageSharp.gatsbyImageData} alt="logo Agicap" />
              </Paragraph>
              <Paragraph style={{ color: 'black', textAlign: 'center' }}>Alternative Agicap</Paragraph>
            </div>
          </a>
        </Col>

        <Col xs={24} lg={8} style={{ marginTop: 20 }}>
          <a href='/turbopilot-alternative/'>
            <div className="rc-quote-container" style={{ display: 'flex', alignItems: 'center' }}>
              <Paragraph>
                <GatsbyImage image={data.turbopilotLogoImage.childImageSharp.gatsbyImageData} alt="logo TurboPilot" />
              </Paragraph>
              <Paragraph style={{ color: 'black' }}>Alternative TurboPilot</Paragraph>
            </div>
          </a>
        </Col>
      </Row>

      <br />
      <br />

      <iframe loading="lazy" width="560" height="315" className='max-w-full' title="Découvrez les alternatives à RocketChart" src="https://www.youtube.com/embed/R6TX4i8AoUI?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center" style={{ marginBottom: '60px' }}>
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Avis clients</Title>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
              <GatsbyImage image={data.studiomaticImage.childImageSharp.gatsbyImageData} alt="logo Studiomatic" />
            </div>
            <Title level={4}>« RocketChart me permet d’avoir une vision instantanée sur l’état de ma trésorerie, et m’aide à prendre rapidement les bonnes décisions grâce aux scénarios prévisionnels de trésorerie : lorsqu’on doit arbitrer un investissement ou une dépense particulière. Le gain de temps est inestimable ! »</Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GatsbyImage image={data.aurelienImage.childImageSharp.gatsbyImageData} alt="Aurelien Lovalente - Co-fondateur de Studiomatic" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                <Text strong>Aurelien Lovalente</Text>
                <Text>Co-fondateur - Studiomatic</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
              <GatsbyImage image={data.welybImage.childImageSharp.gatsbyImageData} alt="logo Welyb" />
            </div>
            <Title level={4}>« RocketChart est un outil hyper intuitif me permettant de suivre ma tréso en temps réel. L'affectation des opérations est très simple et sa fonctionnalité d'export des données me permet de mettre à jour très facilement mon business plan sur Google Sheet. J'adore ! »</Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GatsbyImage image={data.benoitImage.childImageSharp.gatsbyImageData} alt="Benoît Maury - Co-fondateur & CEO de Welyb" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                <Text strong>Benoît Maury</Text>
                <Text>Co-fondateur & CEO - Welyb</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={{ span: 24 }} align='center' style={{ marginTop: '50px' }}>
          <a href="#clients">
            <Button size='large' className="rc-cta">Voir plus d'avis</Button>
          </a>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '0px', marginBottom: '60px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>
          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default Alternatives
